
import Vue from 'vue'
import { Component, Prop } from 'nuxt-property-decorator'
import BaseGridLayout from '../layouts/BaseGridLayout.vue'
import BaseIcon from './BaseIcon.vue'
import { trackFormElementClick } from '../../shared/general/tracking/TrackingService'
import { IFormData } from '../../shared/fsxa/interfaces/IFormPayload'

declare global {
  interface JQuery {
    datepicker : (arg : 'show' | Record<string, any>) => void
    datetimepicker : (arg : Record<string, any>) => void
  }

  interface JQueryStatic {
    datepicker : {
      regional : Record<string, Record<string, any> | undefined>
    }
  }
}

@Component({
  name: 'BaseForm',
  components: {
    BaseGridLayout,
    BaseIcon,
  },
})
export default class BaseForm extends Vue {
  private static loadedScripts : string[] = []

@Prop({ required: true }) locale! : string

  @Prop({ required: true }) form! : IFormData

  @Prop() anchorName ?: string

  private mwfUrl : string = `${window.location.origin}/mwf_live/servlet/form`

  private initialized : boolean = false

  private buttonReplaceListenerActive : boolean = false

  mounted () {
    if (!window.initForm) window.initForm = () => {}

    const target = this.$config.WEBFORMS_PROXY_TARGET
    if (!target) return

    this.addScriptOnce(`${target}/static/webforms.js`, this.loadMWF)
  }

  private get datepickerDefaults () : Record<string, any> {
    const { regional } = $.datepicker

    // Loading the default texts and values for the current locale. Unfortunately, jQuery UI doesn't use the same
    // language tag format for all regional defaults. For example, we have to use "zh-CN" for simplified Chinese,
    // but "de" for German and "" (empty string) for English. Therefore, we have to try firstly the full language tag,
    // then the language code only and finally the empty string for English as fallback.

    let locale = this.locale.replaceAll('_', '-')
    let defaults = regional[locale]

    while (locale && !defaults) {
      const lastIndex = locale.lastIndexOf('-')
      locale = locale.substring(0, lastIndex)
      defaults = regional[locale]
    }

    return defaults || regional[''] || {}
  }

  private loadMWF () : void {
    window.jQuery.mwfAjaxReplace({
      uid: `${this.form.uid}`,
      selector: `#ajaxreplace${this.form.uid}`,
      url: this.mwfUrl,
      appendUrlVars: true,
      data: {
        _view: 'webform',
        _fd: `${this.form.fd}`,
        _refs: '',
        _lang: `${this.form.lang}`,
        _ticket: '&_ticket=',
      },
    })

    const webformsElement = this.$el.querySelector('.mwf-form') as HTMLElement
    const callbacks = [
      () => this.disableSubmitButtonOnClick(webformsElement),
      () => this.addTracking(webformsElement),
      this.initTooltip,
      () => this.inputFocusHandler(webformsElement),
      () => this.accordionFunctionality(webformsElement),
      () => this.addScriptOnce(
        '/jquery-ui.js',
        () => {
          this.registerAllDatePickers()
          this.addScriptOnce('/jquery-ui-timepicker-addon.js', this.registerAllDateTimePickers)
        },
      ),
      () => { this.initialized = true },
    ]

    this.waitForWebformsToLoad(webformsElement, callbacks)
  }

  private registerAllDatePickers () : void {
    this.$el
      .querySelectorAll('.datearea.date .webform-date')
      .forEach((element) => this.registerDatePicker($(element), 'datepicker'))
  }

  private registerAllDateTimePickers () : void {
    this.$el
      .querySelectorAll('.datearea.datetime .webform-date')
      .forEach((element) => this.registerDatePicker($(element), 'datetimepicker'))
  }

  private registerDatePicker (element : JQuery<Element>, initFunctionName : 'datepicker' | 'datetimepicker') : void {
    element.attr('readonly', 'true')

    const format = element.attr('data-date-format')
    const weekdays = this.parseWeekdays(element.attr('data-weekdays'))
    const minDate = element.attr('data-min-date')
    const maxDate = element.attr('data-max-date')
    const minTime = element.attr('data-min-time')
    const maxTime = element.attr('data-max-time')
    const leadTime = element.attr('data-lead-time')
    const pastEnabled = element.attr('data-past') !== 'false'
    const futureEnabled = element.attr('data-future') !== 'false'

    // Add lead time to the current date with a precision of 15 minutes for ensuring that only the minutes 0, 15, 30,
    // and 60 will be offered as selection options.
    const leadDate = leadTime && new Date(this.ceil(+leadTime * 60 * 1000 + Date.now(), 15 * 60 * 1000))

    element[initFunctionName]({
      ...this.datepickerDefaults,
      dateFormat: format,
      minDate: pastEnabled || !futureEnabled ? minDate && new Date(minDate) : leadDate || '+1d',
      maxDate: futureEnabled || !pastEnabled ? maxDate && new Date(maxDate) : '-1d',
      minTime,
      maxTime,
      stepMinute: 15,
      showSecond: false,
      showMillisec: false,
      showMicrosec: false,
      showTimezone: false,
      beforeShowDay: (date : Date) : [boolean, string] => [weekdays.includes(date.getDay()), ''],
      onSelect: () => element.parent().addClass('active'),
    })

    element
      .siblings('.dp-choose')
      .click(() => element.datepicker('show'))
  }

  private parseWeekdays (text : string | undefined) : number[] {
    const defaultWeekdays = [0, 1, 2, 3, 4, 5, 6]

    if (!text) return defaultWeekdays

    const weekdays = text.split(',').filter((value) => /[0-6]/.test(value)).map((value) => Number(value))
    return weekdays.length ? weekdays : defaultWeekdays
  }

  private waitForWebformsToLoad (webformsElement : HTMLElement, callbacks : Function[]) : Promise<void> {
    const isHtmlElement = (node : Element | Node) : node is Element => 'classList' in node

    return new Promise((resolve, reject) => {
      if (!webformsElement) {
        reject()
        return
      }

      const observer = new MutationObserver(([mutation]) => {
        const removedElements = [...(mutation.removedNodes || [])].filter(isHtmlElement)
        const addedElements = [...(mutation.addedNodes || [])].filter(isHtmlElement)

        // mwf-form is removed, remove trackings
        if (removedElements.some((element) => element.classList.contains('mwf-form'))) {
          webformsElement.removeEventListener('click', this.clickTracker)
        }
        // if mwf-form is added, add all the callbacks and trackings
        if (addedElements.some((element) => element.classList.contains('mwf-form'))) {
          this.initialized = false
          callbacks.forEach((callback) => callback())
        }
        resolve()
      })

      observer.observe(webformsElement, { childList: true })
    })
  }

  private initTooltip () : void {
    const tooltipIcons = this.$el.querySelectorAll('.tooltip')
    if (!tooltipIcons.length) return

    tooltipIcons.forEach((tooltipIcon : Element) => {
      const tooltipContent : HTMLElement | null = tooltipIcon.closest('div')
        ?.querySelector('.tooltip_templates') as HTMLElement

      if (!tooltipContent) return
      // add close icon
      this.addTooltipCloseIcon(tooltipContent)

      // show and hide tooltip on click
      this.toggleTooltipContent(tooltipIcon, tooltipContent)

      // adjust tooltip content position on resize
      window.addEventListener('resize', () => {
        this.positionTooltipContent(tooltipIcon, tooltipContent)
      })
    })

    // close tooltip on outside click
    this.closeTooltipContentOnOutsideClick(tooltipIcons)
  }

  private inputFocusHandler (webformsElement : HTMLElement) : void {
    if (!webformsElement) return

    webformsElement.querySelectorAll('.input > input').forEach((input) => {
      (input as HTMLInputElement).oninput = () => {
        input.parentElement?.classList[(input as HTMLInputElement).value ? 'add' : 'remove']('active')
      }
    })
  }

  private toggleTooltipContent (tooltipIcon : Element, tooltipContent : HTMLElement) : void {
    if (this.initialized) return

    tooltipIcon.addEventListener('click', () => {
      if (tooltipContent.style.display === 'block') {
        tooltipContent.style.display = 'none'
      } else {
        tooltipContent.style.display = 'block'
        this.positionTooltipContent(tooltipIcon, tooltipContent)
      }
    })
  }

  private positionTooltipContent (tooltipIcon : Element, tooltipContent : HTMLElement) : void {
    const tooltipIconPositionX = tooltipIcon.getBoundingClientRect().x
    const tooltipIconPositionY = tooltipIcon.getBoundingClientRect().y
    const tooltipIconScrolledPositionY = tooltipIconPositionY + window.scrollY

    // check viewport space to show tooltip content on top or bottom of tooltip icon
    if (tooltipIconPositionY - tooltipContent.offsetHeight <= 0) {
      tooltipContent.style.top = `${tooltipIconScrolledPositionY + 28}px`
    } else {
      tooltipContent.style.top = `${tooltipIconScrolledPositionY - 5 - tooltipContent.offsetHeight}px`
    }

    tooltipContent.style.left = `${tooltipIconPositionX}px`
  }

  private addTooltipCloseIcon (tooltipContent : HTMLElement) : void {
    const closeIconElement = document.createElement('span')
    closeIconElement.className = 'icon-close'
    tooltipContent.prepend(closeIconElement)

    closeIconElement.addEventListener('click', () => {
      tooltipContent.style.display = 'none'
    })
  }

  private closeTooltipContentOnOutsideClick (tooltipIcons : NodeList) : void {
    if (this.initialized) return

    document.addEventListener('click', (event : MouseEvent) => {
      tooltipIcons.forEach((tooltipIcon : Node) => {
        const tooltipContent : HTMLElement | null = (tooltipIcon as HTMLElement).closest('div')
          ?.querySelector('.tooltip_templates') as HTMLElement

        if (!tooltipContent || tooltipContent.contains(event.target as Node) || event.target === tooltipIcon) return
        tooltipContent.style.display = 'none'
      })
    })
  }

  private clickTracker (event : MouseEvent) : void {
    const formElement = event.target as HTMLInputElement
    const type = this.getType(formElement)
    switch (type) {
      case 'textarea':
      case 'text':
      case 'radio':
      case 'checkbox':
      case 'select':
      case 'file':
        trackFormElementClick(formElement, {
          form_field_text: formElement.name,
          form_field_type: type,
        })
        break
      case 'buttonParent': {
        const child = formElement.querySelector('input')
        if (!child) return

        trackFormElementClick(child, {
          form_button_text: child.value,
          form_button_type: JSON.parse(formElement.dataset.mwfSubmit || '{}').type || '',
        })
      }
        break
      case 'button':
        trackFormElementClick(formElement, {
          form_button_text: formElement.value,
          form_button_type: JSON.parse(formElement.parentElement?.dataset?.mwfSubmit || '{}').type || '',
        })
        break
      default:
        break
    }
  }

  private addTracking (webformsElement : HTMLElement) : void {
    if (!webformsElement || this.initialized) return

    webformsElement.addEventListener('click', this.clickTracker)
  }

  private getType (element : HTMLInputElement) : string {
    switch (element.tagName) {
      case 'INPUT':
        return element.type
      case 'TEXTAREA':
        return 'textarea'
      case 'SELECT':
        return 'select'
      case 'SPAN':
        return element.dataset.mwfSubmit ? 'buttonParent' : ''
      default:
        return ''
    }
  }

  private disableSubmitButtonOnClick (webformsElement : HTMLElement) : void {
    if (!webformsElement || this.buttonReplaceListenerActive) return
    this.buttonReplaceListenerActive = true

    const replaceSubmitButton = (button : HTMLElement) => {
      if (!button.dataset.mwfSubmit) return

      try {
        const buttonType = JSON.parse(button.dataset.mwfSubmit)
        if (buttonType.type !== 'finish') return
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        return
      }

      const { parentElement } = button
      const buttonText = button.querySelector('input')?.value || 'SUBMIT'
      // we can remove and replace the button safely since it's being re-added if there is an error in the form
      button.remove()

      const newButton = document.createElement('span')
      newButton.innerHTML = `${buttonText}`
      newButton.classList.add('btn')
      newButton.style.backgroundColor = '#e5e5e5'
      newButton.style.color = '#006b52'

      parentElement?.appendChild(newButton)
    }

    webformsElement.addEventListener('click', (event) => {
      const formElement = event.target as HTMLInputElement
      const type = this.getType(formElement)
      switch (type) {
        case 'buttonParent':
          replaceSubmitButton(formElement)
          break
        case 'button': {
          const { parentElement } = formElement
          if (!parentElement) return

          replaceSubmitButton(parentElement)
        }
          break
        default:
          break
      }
    })
  }

  private accordionFunctionality (webformsElement : HTMLElement) : void {
    if (!webformsElement || this.initialized) return

    webformsElement.addEventListener('click', (event) => {
      const formElement = event.target as HTMLInputElement

      if (formElement.classList.contains('r-tabs-anchor')) {
        event.preventDefault()
        formElement.parentElement?.classList.toggle('r-tabs-state-active')
        document.querySelector(`${formElement.getAttribute('href')}`)?.classList.toggle('r-tabs-state-active')
      }
    })
  }

  private addScriptOnce (script : string, init : () => void) : void {
    if (BaseForm.loadedScripts.includes(script)) {
      this.$nextTick(init)
      return
    }

    let element = document.querySelector(`[src='${script}']`)
    if (element) {
      element.addEventListener('load', init)
      return
    }

    element = document.createElement('script')
    element.setAttribute('src', script)
    element.addEventListener('load', () => BaseForm.loadedScripts.push(script))
    element.addEventListener('load', init)
    document.head.appendChild(element)
  }

  private ceil (value : number, precision : number) : number {
    return Math.ceil(value / precision) * precision
  }
}
